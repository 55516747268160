import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Lightbulb from 'assets/images/lightbulb.png'
import Stylus from 'assets/images/stylus_note.png'
import Bolt from 'assets/images/electric_bolt.png'

import Spacer from 'ui/layout/Spacer'
import { callJSONApi } from 'libs/api'
import { getENV } from 'libs/utils'
import Spinner from 'components/loaders/Spinner'
import { showMessage } from 'redux/modules/notifications'
import classes from './migrate.module.scss'

const Row = ({ h, children }) => {
  return (
    <div
      style={{
        height: `${h}px`,
        display: 'flex',
        margin: '16px 0',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  )
}
Row.propTypes = {
  h: PropTypes.number.isRequired,
  children: PropTypes.node,
}
Row.defaultProps = {
  children: null,
}

const Info = ({ icon, label, description }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '16px' }}>
        <img src={icon} alt='' style={{ width: '24px' }} />
      </div>
      <div>
        <h3 className={classes.infoLabel}>{label}</h3>
        <p className={classes.subtitle}>{description}</p>
      </div>
    </div>
  )
}
Info.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

const MigrateButton = ({ errorFn }) => {
  const [loading, setLoading] = useState(false)

  return (
    <button
      type='button'
      className={classes.sausageButton}
      onClick={() => {
        setLoading(true)
        callJSONApi('/user/hubSessionMigrationToken', 'POST', {}, (response) => {
          setLoading(false)
          if (response.data.hubSessionMigrationToken) {
            window.location = `${getENV('HUB_BASE_URL')}/login/landing?yat=${
              response.data.hubSessionMigrationToken
            }&next=${encodeURIComponent('/hub')}`
          } else {
            errorFn()
          }
        })
      }}
    >
      {loading ? <Spinner style={{ width: '24px', height: '24px' }} /> : 'Switch Now'}
    </button>
  )
}
MigrateButton.propTypes = {
  errorFn: PropTypes.func.isRequired,
}

const MigratePage = (props) => {
  return (
    <div>
      <div className={classes.backgroundGradients}>
        <div className={classes.bg}>
          <div className={classes.mainCircle} />
        </div>
      </div>
      <div className={classes.contentColumn}>
        <Spacer size='medium' />
        <h1>Yoco Business Portal is now the new Yoco Web App</h1>
        <p className={classes.subtitle}>
          A simpler, better way to manage your business, with all the tools you need.
        </p>
        <Spacer size='xs' />
        <MigrateButton
          errorFn={() =>
            props.dispatch(
              showMessage('Error migrating. Please try again later or contact support.', 'danger')
            )
          }
        />
        <Spacer size='xs' />
        <Info
          icon={Stylus}
          label='Better by redesign'
          description='Less cluttered, more organised, more focused. A better way to understand, manage and run your business.'
        />
        <Info
          icon={Lightbulb}
          label='Actionable insights'
          description='Discover new, exciting insights about your Products, Staff and Sales. Drill into the details or see the bigger picture at a glance.'
        />
        <Info
          icon={Bolt}
          label='Faster, simpler management'
          description='Act with speed, confidence and control. Add customers, send invoices, manage staff access to Yoco POS, take an Instant Payout, and much more!'
        />

        <Spacer size='medium' />
      </div>
    </div>
  )
}

MigratePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const ReduxConnectedPage = connect()(MigratePage)

export default ReduxConnectedPage
