import { hasFeature } from 'libs/features'

export const wasRoutedFromApp = (state) => {
  const inApp = state?.routing?.locationBeforeTransitions?.query['in-app']
  return inApp ? inApp === true || inApp.toLowerCase() === 'true' : false
}

export const isTablet = (state) => {
  const deviceType = state?.routing?.locationBeforeTransitions?.query['device-type']
  return deviceType ? deviceType.toLowerCase() === 'tablet' : false
}

export const isHubRoutingEnabled = (state) => {
  console.debug(
    'debug: isHubRoutingEnabled',
    hasFeature(state, 'hubMigrate2024', false),
    !hasFeature(state, 'linkedAccounts', false),
    !wasRoutedFromApp(state)
  )
  return (
    hasFeature(state, 'hubMigrate2024', false) &&
    !hasFeature(state, 'linkedAccounts', false) &&
    !wasRoutedFromApp(state)
  )
}
